<template>
  <div>
    <v-form
      ref="donation_form"
      lazy-validation
    >
      <v-row>
        <v-col cols="12" :sm="!form.donor_id && !form.donation_id && !form.quick ? 4 : 6">
          <v-card>
            <v-card-title>Temple Details</v-card-title>
            <v-card-text>
              <v-select
                v-if="$store.state.me && $store.state.me.role === 'admin'"
                outlined
                dense
                clearable
                label="Accept At *"
                placeholder="Accept At"
                item-value="id"
                item-text="name"
                :items="templeOptions"
                v-model="form.location_id"
                :error-messages="errors.location_id"
                :rules="[v => !!v || 'Accept Location Required']"
                required
              />
              <v-select
                v-if="($store.state.me && $store.state.me.role !== 'admin') || form.location_id"
                outlined
                dense
                :clearable="!form.donation_id"
                label="Temple *"
                :readonly="!!form.donation_id"
                placeholder="Temple"
                item-value="id"
                item-text="name"
                :items="templeOptions"
                v-model="form.temple_id"
                :error-messages="errors.temple_id"
                :rules="[v => !!v || 'Temple Required']"
                required
              />
              <v-select
                v-if="$store.state.me && ($store.state.me.role !== 'admin' || form.location_id) && form.temple_id && !$route.query.donation_type_id && !form.quick"
                outlined
                dense
                clearable
                :readonly="!!form.donation_id"
                label="Celebration (Optional)"
                placeholder="Celebration"
                item-value="id"
                item-text="name"
                :items="celebrationOptions"
                v-model="form.celebration_id"
                :error-messages="errors.celebration_id"
              />
              <v-select
                v-if="($store.state.me && ($store.state.me.role !== 'admin' || form.location_id) && form.temple_id) || $route.query.donation_type_id"
                outlined
                dense
                clearable
                :readonly="!!form.donation_id || !!$route.query.donation_type_id"
                label="Donation Category *"
                placeholder="Donation Category"
                item-value="id"
                item-text="name"
                :items="donationTypeOptions"
                v-model="form.donation_type_id"
                :error-messages="errors.donation_type_id"
                :rules="[v => !!v || 'Donation Category Required']"
                required
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" :sm="!form.donor_id && !form.donation_id && !form.quick ? 4 : 6">
          <v-card>
            <v-card-title>Donation Details</v-card-title>
            <v-card-text>
              <v-switch
                v-if="!form.quick"
                dense
                :readonly="!!form.donation_id"
                class="mt-0"
                label="Private Donation"
                v-model="form.private"
              />
              <v-menu
                offset-y
                :nudge-right="40"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                v-model="openDatePicker"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    readonly
                    :prepend-icon="icons.mdiCalendar"
                    v-bind="attrs"
                    v-on="on"
                    label="Date"
                    v-model="form.date"
                    :error-messages="errors.date"
                  />
                </template>
                <v-date-picker v-if="!form.donation_id" v-model="form.date"
                               @input="openDatePicker = false"/>
              </v-menu>
              <v-text-field
                v-if="!form.quick"
                outlined
                dense
                :readonly="!!form.donation_id"
                type="number"
                label="Contact Number"
                placeholder="Contact Number"
                v-model="form.mobile"
                :error-messages="errors.mobile"
                @input="suggestDonor"
              />
              <v-text-field
                outlined
                dense
                :readonly="!!form.donation_id"
                class="grouped-input"
                label="Name *"
                placeholder="Name"
                v-model="form.name"
                :error-messages="errors.name"
              />
              <v-row>
                <!--                <v-col cols="12" v-if="form.quick">-->
                <!--                  -->
                <!--                </v-col>-->
                <v-col cols="12"
                       :md="!form.quick && !form.private ? (form.donation_id ? 4 : 6) : 12"
                >
                  <!-- Fields for Edit Donation -->
                  <template v-if="form.donation_id">
                    <v-text-field
                      v-if="!!form.donation.units"
                      outlined
                      dense
                      readonly
                      type="number"
                      label="Units"
                      placeholder="Units"
                      :value="form.donation.units"
                      :messages="`₹${form.donation.amount}`"
                    />
                    <v-text-field
                      v-else
                      outlined
                      dense
                      readonly
                      type="number"
                      prefix="₹"
                      label="Amount"
                      placeholder="Amount"
                      :value="form.donation.amount"
                    />
                  </template>
                  <template v-else>
                    <v-text-field
                      v-if="canShowUnits"
                      outlined
                      dense
                      type="number"
                      label="Units"
                      placeholder="Units"
                      v-model="form.units"
                      :error-messages="errors.units"
                      :messages="form.amount ? `₹${form.amount}` : ''"
                    />
                    <template v-else-if="form.quick">
                      <p class="mb-2">Amount</p>
                      <v-slide-group
                        @change="setAmount"
                        v-model="quickAmount"
                        show-arrows
                        mandatory
                        class="pb-3"
                      >
                        <v-slide-item
                          v-for="quickAmount in quickAmounts"
                          :key="quickAmount"
                          v-slot="{ active, toggle }"
                          :value="quickAmount"
                        >
                          <v-btn
                            small
                            :input-value="active"
                            active-class="primary white--text"
                            depressed
                            rounded
                            @click="toggle"
                          >
                            {{ quickAmount }}
                          </v-btn>
                        </v-slide-item>
                      </v-slide-group>
                    </template>

                    <v-text-field
                      v-if="!canShowUnits && showAmountField"
                      outlined
                      dense
                      type="number"
                      prefix="₹"
                      label="Amount *"
                      placeholder="Amount"
                      v-model="form.amount"
                      @input="updateDepositAmount"
                      :error-messages="errors.amount"
                    />
                  </template>
                </v-col>
                <v-col v-if="!form.quick && !form.private" cols="12" :md="form.donation_id ? 4 : 6">
                  <v-text-field
                    v-if="form.donation_id"
                    outlined
                    dense
                    readonly
                    type="number"
                    prefix="₹"
                    label="Remaining"
                    placeholder="Remaining"
                    :value="form.donation.remaining"
                  />
                  <v-text-field
                    v-else
                    outlined
                    dense
                    :readonly="!!form.id"
                    type="number"
                    prefix="₹"
                    label="Deposited"
                    placeholder="Deposited"
                    v-model="form.private ? form.amount : form.deposited"
                    @input="setDeposited"
                    :error-messages="errors.deposited"
                  />
                </v-col>
                <v-col v-if="form.donation_id" cols="12" md="4">
                  <v-text-field
                    outlined
                    dense
                    prefix="₹"
                    label="Accepting the amount now"
                    placeholder="Accepting the amount now"
                    v-model="form.amount"
                    :error-messages="errors.amount"
                  />
                </v-col>
              </v-row>
              <v-textarea
                v-if="!form.quick"
                outlined
                dense
                :readonly="!!form.donation_id"
                label="Haste"
                placeholder="Haste"
                v-model="form.haste"
                :error-messages="errors.haste"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="!form.donor_id && !form.donation_id && !form.quick" cols="12" sm="4">
          <v-card>
            <v-card-title>Donor Details</v-card-title>
            <v-card-text>
              <v-combobox
                outlined
                dense
                label="Country"
                placeholder="Country"
                item-text="name"
                item-value="iso2"
                :return-object="false"
                :items="countryOptions"
                v-model="form.country"
                :error-messages="errors.country"
              />
              <v-combobox
                outlined
                dense
                label="State"
                placeholder="State"
                item-text="name"
                item-value="iso2"
                :return-object="false"
                :items="stateOptions"
                v-model="form.state"
                :error-messages="errors.state"
              />
              <v-combobox
                outlined
                dense
                label="City/Village"
                placeholder="City/Village"
                :items="cityOptions"
                v-model="form.village_city"
                :error-messages="errors.village_city"
              />
              <v-text-field
                outlined
                dense
                label="PIN Code"
                placeholder="PIN Code"
                v-model="form.pincode"
                :error-messages="errors.pincode"
              />
              <v-text-field
                outlined
                dense
                label="PAN Number"
                placeholder="PAN Number"
                v-model="form.pan_number"
                :error-messages="errors.pan_number"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer/>
        <v-btn outlined class="ml-2" color="secondary" :to="{name: 'donations'}">Cancel</v-btn>
        <v-btn outlined class="ml-2" color="primary" @click="save">Save</v-btn>
      </v-row>
    </v-form>
    <v-snackbar v-model="snackbar.active" :timeout="snackbar.timeout" :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar.active = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>

import _ from 'lodash';
import {mdiClose, mdiMagnify, mdiCalendar} from '@mdi/js';
import nameTitles from '@/utils/nameTitles';

let schema = {
  quick: false, //Setting true will need to do additional modifications for edit page.
  donation_id: null,
  location_id: null,
  temple_id: null,
  celebration_id: null,
  donation_type_id: null,
  private: false,
  date: null,
  mobile: null,
  title: null,
  name: null,
  haste: null,
  units: null,
  amount: null,
  deposited: null,
  note: null,
  donor_name: null,
  address_1: null,
  address_2: null,
  pincode: null,
  village_city: null,
  state: null,
  country: null,
  native: null,
  pan_number: null,
  donation: {},
};

export default {
  data() {
    return {
      isDepositModified: false,
      form: {
        ...{id: null},
        ...schema,
      },
      errors: {},
      templeOptions: [],
      celebrationOptions: [],
      donationTypeOptions: [],
      countryOptions: [],
      stateOptions: [],
      cityOptions: [],
      nativeOptions: [],
      donationOptions: [],
      openDatePicker: false,
      nameTitles: nameTitles,
      snackbar: {
        active: false,
        message: null,
        timeout: -1,
        color: null
      },
      icons: {
        mdiClose,
        mdiMagnify,
        mdiCalendar,
      },
      quickAmounts: [51, 101, 151, 201, 251, 501, 'Other'],
      quickAmount: null,
      showAmountField: true,
    };
  },
  watch: {
    'form.location_id'() {
      if (!this.$route.query.donation_type_id) {
        this.updateCelebrationOptions();
        this.updateDonationTypeOptions();
      }
    },
    'form.temple_id'() {
      if (!this.$route.query.donation_type_id) {
        this.updateCelebrationOptions();
        this.updateDonationTypeOptions();
      }
    },
    'form.celebration_id'() {

      this.updateDonationTypeOptions();
    },
    'form.units'() {
      this.form.amount = (this.form.units) ? this.form.units * this.donationTypeOptions.find(item => item.id === this.form.donation_type_id).per_unit : 0;
    },
    'form.country'(newVal) {

      this.updateStateOptions(newVal);
    },
    'form.state'(newVal) {

      this.updateCityOptions(this.form.country, newVal);
    },
  },
  computed: {
    canShowUnits() {
      return !!this.form.donation_type_id && this.donationTypeOptions.find(item => item.id === this.form.donation_type_id) && this.donationTypeOptions.find(item => item.id === this.form.donation_type_id).per_unit;
    }
  },
  async created() {
    if (this.$route.name === 'add-donation') {
      this.form.date = this.$moment().format('YYYY-MM-DD');
      this.form.quick = this.$route.params.type === 'quick';
      if(this.$store.state.me && this.$store.state.me.temple) {
        this.form.temple_id = this.$store.state.me.temple.id;
      }
    }
    this.loading = true;
    if (!this.form.quick) {
      try {
        let citiesResponse = await axiosInstanceCSC.get('/countries/IN/cities')
        this.nativeOptions = citiesResponse.data.map(item => item.name).sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0));

        let countriesResponse = await axiosInstanceCSC.get('/countries')
        this.countryOptions = countriesResponse.data.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      } catch (error) {
        console.error(error);
      }
    }

    let templeResponse = await axiosBackend.get('/options/temples')
    this.templeOptions = templeResponse.data.temples;

    if (this.$route.name === 'add-donation') {
      if (this.$route.query.donation_type_id) {
        let donationTypeResponse = await axiosBackend.get('/options/donation-types')
        this.donationTypeOptions = donationTypeResponse.data.donation_types.filter(item => item.id === parseInt(this.$route.query.donation_type_id));
        this.form.donation_type_id = parseInt(this.$route.query.donation_type_id);
        this.loading = false;
      } else {
        this.loading = false;
      }
    } else if (this.$route.name === 'edit-donation' || this.$route.name === 'add-installment') {
      let donationResponse = await axiosBackend.get('/donations/' + this.$route.params.id)
      if (this.$route.name === 'edit-donation') {
        this.form = {
          ...donationResponse.data.donation, ...{
            deposited: donationResponse.data.donation.first_installment.amount
          }
        };
        console.log(this.form);
        this.updateDonationTypeOptions();
      } else {
        this.form = {
          donation_id: donationResponse.data.donation.id,
          temple_id: donationResponse.data.donation.temple_id,
          celebration_id: donationResponse.data.donation.celebration_id,
          donation_type_id: donationResponse.data.donation.donation_type_id,
          date: donationResponse.data.donation.date,
          mobile: donationResponse.data.donation.donor ? donationResponse.data.donation.donor.mobile : null,
          title: donationResponse.data.donation.title,
          name: donationResponse.data.donation.name,
          haste: donationResponse.data.donation.haste,
          deposited: donationResponse.data.donation.deposited,
          note: donationResponse.data.donation.note,
          donation: {
            amount: donationResponse.data.donation.amount,
            remaining: donationResponse.data.donation.remaining
          },
        };
      }
      if (donationResponse.data.donation.donor) {
        this.form.mobile = donationResponse.data.donation.donor.mobile;
        this.form.donor_name = donationResponse.data.donation.donor.name;
        this.form.address_1 = donationResponse.data.donation.donor.address_1;
        this.form.address_2 = donationResponse.data.donation.donor.address_2;
        this.form.pincode = donationResponse.data.donation.donor.pincode;
        this.form.country = donationResponse.data.donation.donor.country;
        this.form.state = donationResponse.data.donation.donor.state;
        this.form.village_city = donationResponse.data.donation.donor.village_city;
        this.form.native = donationResponse.data.donation.donor.native;
        this.form.pan_number = donationResponse.data.donation.donor.pan_number;
      }
      this.loading = false;

      if(this.form.quick) {
        //Setting Quick Amount
        if(this.quickAmounts.indexOf(this.form.amount) > -1) {
          this.quickAmount = this.form.amount;
        } else {
          this.quickAmount = 'Other';
          this.showAmountField = true;
        }
      }

    } else if (this.$route.name === 'edit-installment') {
      let installmentsResponse = await axiosBackend.get('/installments/' + this.$route.params.id)
      this.form = {
        ...installmentsResponse.data.installment,
        ...{
          location_id: null,
          temple_id: installmentsResponse.data.installment.donation.temple_id,
          celebration_id: installmentsResponse.data.installment.donation.celebration_id,
          donation_type_id: installmentsResponse.data.installment.donation.donation_type_id,
          quick: installmentsResponse.data.installment.donation.quick,
          private: installmentsResponse.data.installment.donation.private,
          mobile: installmentsResponse.data.installment.donation.donor ? installmentsResponse.data.installment.donation.donor.mobile : null,
          date: installmentsResponse.data.installment.donation.date,
          title: installmentsResponse.data.installment.donation.title,
          name: installmentsResponse.data.installment.donation.name,
          haste: installmentsResponse.data.installment.donation.haste,
          note: installmentsResponse.data.installment.donation.note,
        }
      };
      this.loading = false;
    }
  },
  methods: {
    save() {
      if(!this.$refs.donation_form.validate()) {
        return;
      }
      this.loading = true;
      let apiURL = this.form.donation_id ? '/installments' : '/donations';
      if (this.form.id) apiURL += '/' + this.form.id;

      axiosBackend.post(apiURL, this.form)
        .then(response => {
          this.$router.push({name: 'donations'});
        })
        .catch((error) => {
          if (error.response.status === 422) this.errors = error.response.data.errors;
          this.snackbar = {
            active: true,
            color: 'error',
            timeout: 5000,
            message: error.response.data.message,
          };
        });
    },
    suggestDonor: _.debounce(function () {
      axiosBackend.get('/donors/suggest', {params: {mobile: this.form.mobile}})
        .then(response => {

          this.form.donor_id = response.data.donor.id;
          this.form.donor_name = response.data.donor.name;
          this.form.address_1 = response.data.donor.address_1;
          this.form.address_2 = response.data.donor.address_2;
          this.form.pincode = response.data.donor.pincode;
          this.form.village_city = response.data.donor.village_city;
          this.form.state = response.data.donor.state;
          this.form.country = response.data.donor.country;
          this.form.native = response.data.donor.native;
          this.form.pan_number = response.data.donor.pan_number;
          if (!this.form.id) this.form.name = response.data.donor.name;
        }).catch(error => {
        this.form.donor_id = null;
        this.form.donor_name = null;
        this.form.address_1 = null;
        this.form.address_2 = null;
        this.form.pincode = null;
        this.form.village_city = null;
        this.form.state = null;
        this.form.country = null;
        this.form.native = null;
        this.form.pan_number = null;
        this.form.name = null;
      });
    }, 500),
    updateCelebrationOptions() {
      if (this.$store.state.me && (this.$store.state.me.role !== 'admin' || this.form.location_id) && this.form.temple_id && !this.form.quick) {
        axiosBackend.get(`/options/temples/${this.form.temple_id}/celebrations`)
          .then(response => {
            this.celebrationOptions = response.data.celebrations;
            if (!this.celebrationOptions.some(item => item.id === this.form.celebration_id)) this.form.celebration_id = null;
          });
      } else {

        this.form.celebration_id = null;
        this.celebrationOptions = [];
      }
    },
    updateDonationTypeOptions() {
      if (this.$store.state.me && (this.$store.state.me.role !== 'admin' || this.form.location_id) && this.form.temple_id) {
        let url = `/options/temples/${this.form.temple_id}`;
        if (this.form.celebration_id) url += `/celebrations/${this.form.celebration_id}`;
        url += `/donation-types?location_id=${this.form.location_id}`;
        axiosBackend.get(url)
          .then(response => {
            this.donationTypeOptions = response.data.donation_types.filter(item => (!this.form.celebration_id) ? !item.festival_only : true);
            if (!this.donationTypeOptions.some(item => item.id === this.form.donation_type_id)) {
              this.form.donation_type_id = null
            }
          });
      } else {

        this.form.donation_type_id = null;
        this.donationTypeOptions = [];
      }
    },
    updateStateOptions(ciso) {
      return new Promise((resolve, reject) => {
        axiosInstanceCSC.get(`/countries/${ciso}/states`)
          .then(response => {
            this.stateOptions = response.data.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
            resolve();
          }).catch(error => {
          reject(error);
        });
      });
    },
    updateCityOptions(ciso, siso) {
      return new Promise((resolve, reject) => {
        axiosInstanceCSC.get(`/countries/${ciso}/states/${siso}/cities`)
          .then(response => {
            this.cityOptions = response.data.map(item => item.name).sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0));
            if (!this.cityOptions.includes(this.form.village_city)) this.form.village_city = null;
            resolve();
          }).catch(error => {
          reject();
        });
      });
    },
    updateDepositAmount() {
      if (!this.isDepositModified && !this.form.donation_id) {
        this.form.deposited = this.form.amount;
      }
    },
    setDeposited() {
      this.isDepositModified = true;
    },
    setAmount(amount) {
      this.showAmountField = false;
      if(amount === 'Other') {
        this.form.amount = '';
        this.showAmountField = true;
      } else {
        this.form.amount = amount;
      }
    }
  }
}
</script>
